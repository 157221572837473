<template>
  <v-dialog
      v-model="aberto"
      width="600px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      persistent
      @keydown.esc="fechar">
    <v-card class="card-detalhes">
      <v-card-title class="titulo-card cor-principal">
        <div class="d-flex justify-space-between w-100">
          <div>Filtrar Clientes</div>
          <v-btn icon dark @click="fechar" color="orange">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card-title>

      <v-card-text class="mt-5">
        <div class="loading text-center" v-if="loading_dados">
          <v-progress-circular
              size="50"
              color="orange"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-row v-else>

          <v-col cols="12" md="6">
            <v-text-field
                label="Data da Solicitação"
                placeholder="Data da Solicitação"
                outlined
                dense
                hide-details
                v-model="form.criado_em"
                clearable
                type="date"
                prepend-inner-icon="mdi-calendar"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                label="Ultima Atualização"
                placeholder="Ultima Atualização"
                outlined
                dense
                hide-details
                v-model="form.atualizado_em"
                clearable
                type="date"
                prepend-inner-icon="mdi-calendar"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                label="Nome"
                placeholder="Nome do cliente"
                outlined
                dense
                hide-details
                v-model="form.nome"
                clearable
                prepend-inner-icon="mdi-face-man"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
                label="CPF"
                placeholder="CPF do cliente"
                outlined
                dense
                v-model="form.cpf"
                hide-details
                clearable
                prepend-inner-icon="mdi-card-account-details-outline"
                v-mask="'###.###.###-##'"
            />
          </v-col>

          <v-col cols="12" md="6">
            <StatusReanalise :id="form.status_id" v-on:statusSelecionado="statusRetornado"/>
          </v-col>

          <v-col cols="12" md="6">
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
            :disabled="loading_dados"
            color="orange darken-3"
            text
            @click="fechar">Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import StatusReanalise from "@/components/autocomplete/StatusReanalise"

export default {
  name: "DialogoFiltro",
  mixins: [mixinGeral],
  props: {
    aberto: Number | String
  },
  components: {
    StatusReanalise
  },
  data() {
    return {
      options_empresas: [],
      loading_dados: false,
      form: {
        nome: null,
        cpf: null,
        criado_em: null,
        atualizado_em: null,
        status_id: null,
      }
    };
  },
  created() {
    this.$emit('filtrar', this.form)
  },

  watch: {
    aberto: function (val) {
      if (val) {
        this.loading_dados = false
      } else {
        this.loading_dados = true
      }
    }
  },
  methods: {
    statusRetornado(value) {
      this.form.status_id = value
    },
    fechar() {
      this.$emit('fechar', false);
    }
  },

};
</script>

<style scoped>
.card-detalhes {
  border: 2px solid var(--cor-primaria-100);
}

.titulo-card {
  font-size: 20px;
}

.cor-principal {
  color: var(--cor-primaria-100) !important;
}
</style>