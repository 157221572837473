<template>
  <menu-principal>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <titulo-pagina>Reanalise de Cadastro</titulo-pagina>
        </v-col>
        <v-col cols="12">
          <v-card class="rounded-lg">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-btn color="white" class="float-left" small @click="getDados">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-btn color="white" class="mx-3" small @click="modalFiltro = true">
                    <v-icon>mdi-filter</v-icon>
                  </v-btn>

                  <v-chip
                      v-for="(value, key) in filtersChip" :key="key"
                      v-if="(value != null && value.length > 0)"
                      class="mx-3"
                      close
                      color="orange"
                      label
                      outlined
                      @click:close="filtros[key] = null"
                  > {{ key | primeiraLetraMaiuscula }}: {{ value | formataData }}
                  </v-chip>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-data-table
                      :headers="headers"
                      :items="dadosTabela"
                      locale="pt"
                  >
                    <template v-slot:item.criado_em="{ item }">
                      {{ item.criado_em | formataData }}
                      <!--                    <v-btn  @click="printc(item)"> click</v-btn>-->
                    </template>
                    <template v-slot:item.atualizado_em="{ item }">
                      {{ item.atualizado_em | formataData }}
                    </template>
                    <template v-slot:item.reanalise_status.descricao="{ item }">
                      <v-btn text class="rounded-xl" readonly :color="setColorChip(item.status_id)">
                        {{ item.reanalise_status.descricao }}
                      </v-btn>
                    </template>
                    <template v-slot:item.auditar="{ item }">
                      <v-btn icon @click="abrirReanalise(item.id, item.cliente_id)">
                        <v-icon color="orange" medium>mdi-gavel</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <dialog-filtro
          ref="DialogFiltro"
          :aberto="modalFiltro"
          @fechar="modalFiltro = false"
          @filtrar="filtrar"
      />

      <dialog-reanalise
          ref="DialogReanalise"
          :reanalise_id=modelReanalise.reanalise_id
          :cliente_id=modelReanalise.cliente_id
          :aberto=modelReanalise.aberto
          @fechar="fecharReanalise">
      </dialog-reanalise>

    </v-container>
  </menu-principal>
</template>

<script>

import MenuPrincipal from "../../components/MenuPrincipal";
import TituloPagina from "../../components/TituloPagina";
import DialogFiltro from "@/components/reanalise/DialogFiltro";
import mixinGeral from "@/mixin/geral";
import DialogReanalise from "@/components/reanalise/DialogReanalise";
import reanalise from "@/services/reanalise";

export default {
  name: "ReanaliseDeCadastro",
  mixins: [mixinGeral],
  components: {
    MenuPrincipal,
    TituloPagina,
    DialogFiltro,
    DialogReanalise
  },
  data() {
    return {
      dados: [],
      filtros: {
        nome: null,
        cpf: null,
        criado_em: null,
        atualizado_em: null,
        status_id: null
      },
      modalFiltro: false,
      modelReanalise: {
        reanalise_id: null,
        cliente_id: null,
        aberto: false
      },
    }
  },
  async mounted() {
    this.getDados();
    this.filtros.status_id = 1;
  },
  computed: {
    headers() {
      return [
        {text: "Solicitado em", value: "criado_em"},
        {text: "Nome", value: "cliente.nome"},
        {text: "CPF", value: "cliente.cpf"},
        {text: "Ultima Atualização", value: "atualizado_em", align: "center"},
        {text: "Status", value: "reanalise_status.descricao", align: "center"},
        {text: "Auditar Reanalise", value: "auditar", align: "center", width: "10%"},
      ];
    },
    dadosTabela() {
      const dados = this.registrosFiltrados;
      if (dados.length === 0) {
        return []
      }
      return dados;
    },
    filtersChip() {
      const retorno = {};
      if (this.filtros.nome) retorno.nome = `${this.filtros.nome}`;
      if (this.filtros.cpf) retorno.cpf = `${this.filtros.cpf}`;
      if (this.filtros.criado_em) retorno.criado_em = `${this.filtros.criado_em}`;
      if (this.filtros.atualizado_em) retorno.atualizado_em = `${this.filtros.atualizado_em}`;
      switch (this.filtros.status_id) {
        case 1:
          retorno.status_id = `Pendente`;
          break;
        case 2:
          retorno.status_id = `Aprovado`;
          break;
        case 3:
          retorno.status_id = `Negado`;
          break;
      }

      return retorno;
    },
    registrosFiltrados() {
      if (!this.dados) return [];
      let filtrados = [...this.dados];

      const filtrandoNome = !!this.filtros.nome;
      const filtrandoCpf = !!this.filtros.cpf;
      const filtrandocriado_em = !!this.filtros.criado_em;
      const filtrandoatualizado_em = !!this.filtros.atualizado_em;
      const filtrandoSatusId = !!this.filtros.status_id;

      if (filtrandoNome) filtrados = filtrados.filter((f) => {
        return f.cliente.nome.toUpperCase().indexOf(this.filtros.nome.toUpperCase()) !== -1;
      });
      if (filtrandoCpf) filtrados = filtrados.filter((f) => {
        return f.cliente.cpf.toUpperCase().indexOf(this.filtros.cpf.toUpperCase()) !== -1;
      });
      if (filtrandocriado_em) filtrados = filtrados.filter((f) => {
        return f.criado_em.slice(0, 10) === this.filtros.criado_em.slice(0, 10);
      });
      if (filtrandoatualizado_em) filtrados = filtrados.filter((f) => {
        return f.atualizado_em.slice(0, 10) === this.filtros.atualizado_em.slice(0, 10);
      });
      if (filtrandoSatusId) filtrados = filtrados.filter((f) => {
        return f.status_id == this.filtros.status_id
      });
      return filtrados;
    },
  },
  methods: {
    async getDados() {
      await reanalise.listar().then(resp => {
        this.dados = resp.data;
      });
    },

    abrirReanalise(id, cliente_id) {
      this.modelReanalise.reanalise_id = id;
      this.modelReanalise.cliente_id = cliente_id;
      this.modelReanalise.aberto = true;
    },
    fecharReanalise() {
      this.modelReanalise.reanalise_id = null;
      this.modelReanalise.cliente_id = null;
      this.modelReanalise.aberto = false
      this.getDados();
    },
    fechar() {
      this.$emit('fechar')
    },
    filtrar(obj) {
      this.filtros = obj;
    },
  },

}
</script>
