<template>
  <div>
    <v-dialog
        v-model="aberto"
        width="900px"
        persistent
        @keydown.esc="fechar">
      <v-card class="card-detalhes">
        <v-card-title class="titulo-card cor-principal">
          <div class="d-flex justify-space-between w-100">
            <div><span v-if="cadastrando">Nova </span>Reanalise</div>
            <v-btn icon dark @click="fechar" color="orange">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <!--        Form de Cadastro -->
        <v-card-text v-if="reanalise_id == 0">
          <v-row justify="center">
            <v-col cols="10" align="center"><h3>Envie a documentação para Reanalise</h3></v-col>
            <v-col cols="10" md="5" align="center">
              <v-textarea
                  outlined
                  dense
                  rows="1"
                  value=""
                  label="Observação da Auditoria"
                  v-model="form_cadastro.observacao_cliente"
              ></v-textarea>
            </v-col>
            <v-col cols="10" md="5" align="center">
              <v-file-input
                  accept="image/*,.pdf"
                  label="Documentos para Aprovação"
                  countercontain
                  multiple
                  outlined
                  dense
                  v-model="form_cadastro.documentos"
                  prepend-inner-icon="mdi-attachment"
                  prepend-icon=""
              >
              </v-file-input>
            </v-col>
            <v-col cols="10" align="center" v-if="form_cadastro.documentos.length > 0">
              <h4>Arquivos anexados</h4><br>
              <v-chip
                  v-for="(file, key) in form_cadastro.documentos"
                  :key="key"
                  color="orange"
                  class="ma-1"
                  dark
                  close
                  small
                  @click:close="form_cadastro.documentos.splice(key, 1)"
              >{{ file.name | nomeArquivoResumido }}
              </v-chip>
            </v-col>
            <v-col cols="10" align="center" class="mt-5">
              <v-btn
                  dark
                  dense
                  color="orange darken-4"
                  @click="cadastrarReanalise">Enviar Reanalise
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <!--        Form de Auditoria-->
        <v-card-text class="mt-5" v-if="reanalise_id >= 1">
          <v-row>
            <v-col cols="12" align="center"><h3>Arquivos Anexados</h3></v-col>
            <v-col cols="12">
              <v-data-table
                  :headers="headers"
                  :items="reanalise.reanalise_documentos"
                  dense
                  width="100%"
                  hide-default-footer
                  class="ma-5"
              >
                <template v-slot:item.id="{ item }">
                  {{ reanalise.id }}
                </template>
                <template v-slot:item.arquivo="{ item }">
                  {{ item.link | nomeArquivoResumido }}
                </template>
                <template v-slot:item.verarquivo="{ item }">
                  <v-icon color="primary" medium
                          @click="dialogShowArquivo.status = true, dialogShowArquivo.arquivo = item.link">mdi-file
                  </v-icon>
                </template>
                <template v-slot:item.criado_em="{ item }">
                  {{ item.criado_em |formataData }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-divider class="mb-10 mt-5 "></v-divider>
          <div v-if="reanalise.status_id === 1 && permissaoMaster">
            <v-row justify="center">
              <v-col cols="12" align="center"><h3>Observação do Cliente</h3></v-col>
              <v-col cols="12" md="12">
                <v-textarea
                    class="px-6"
                    outlined
                    dense
                    rows="2"
                    disabled
                    :value="reanalise.observacao_cliente"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider class="mb-10 mt-5 "></v-divider>
            <div class="mx-6">
              <v-row justify="center">
                <v-col cols="8">
                  <v-textarea
                      outlined
                      dense
                      rows="2"
                      label="Observação da Auditoria"
                      v-model="form_auditor.observacao_auditor"
                  ></v-textarea>
                </v-col>

                <v-col cols="2">
                  <v-btn
                      dark
                      dense
                      color="red darken-5"
                      class="ma-3"
                      @click="form_auditor.status_id = 3, auditarReanalise()">Negar
                  </v-btn>
                </v-col>

                <v-col cols="2">
                  <v-btn
                      dark
                      dense
                      color="green darken-5"
                      class="ma-3"
                      @click="form_auditor.status_id = 2, auditarReanalise()">Aprovar
                  </v-btn>
                </v-col>
              </v-row>

            </div>

          </div>
          <div v-else>
            <v-row justify="center">
              <v-col cols="12" align="center"><h3>Observação do Cliente</h3></v-col>
              <v-col cols="12" md="12">
                <v-textarea
                    class="px-6"
                    outlined
                    dense
                    rows="2"
                    disabled
                    :value="reanalise.observacao_cliente"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row justify="center" v-if="reanalise.observacao_auditor != null">
              <v-col cols="12" align="center"><h3>Observação do Auditor</h3></v-col>
              <v-col cols="12" md="12">
                <v-textarea
                    class="px-6"
                    outlined
                    dense
                    rows="2"
                    disabled
                    :value="reanalise.observacao_auditor"
                ></v-textarea>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
              color="orange darken-3"
              text
              @click="fechar">Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <dialog-show-arquivo
        ref="DialogShowArquivo"
        :titulo="'Arquivo Anexado para Reanalise'"
        :aberto="dialogShowArquivo.status"
        :url_arquivo="dialogShowArquivo.arquivo"
        @fechar="dialogShowArquivo.status = false"
    >
    </dialog-show-arquivo>


    <v-dialog v-model="dialogIframe" max-width="1200">
      <v-card>
        <v-toolbar flat color="orange" class="mb-5">
          <h3><span class="white--text mr-5">{{ itemIframe.arquivo }}</span></h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialogIframe=false" dark>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-img
              v-if="['jpg', 'png', 'webp'].includes(getTypeImg(itemIframe.link))"
              :src="itemIframe.link"
              max-width="100%"
              max-height="800px"
              contain
          ></v-img>
          <iframe v-else-if="getTypeImg(itemIframe.link) == 'pdf'"
                  :src="itemIframe.link"
                  height="1200"
                  width="100%"
          ></iframe>
          <span v-else> Formato Invalido para Visualização !</span>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="orange darken-3" text @click="dialogIframe = false">Fechar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import mixinGeral from "../../mixin/geral";
import reanalise from "@/services/reanalise";
import DialogShowArquivo from "@/components/DialogShowArquivo";

export default {
  name: "DialogReanalise",
  mixins: [mixinGeral],
  components: {DialogShowArquivo},
  props: {
    reanalise_id: Number | Boolean,
    cliente_id: Number,
    aberto: Boolean
  },
  data() {
    return {
      dialogIframe: false,
      cadastrando: false,
      itemIframe: [],
      dados: {
        reanalise_id: null,
        status_id: 1,
      },
      arquivo: null,
      dialogShowArquivo: {
        status: false,
        arquivo: null,
      },
      form_auditor: {
        reanalise_id: null,
        observacao_auditor: null,
        status_id: null,
      },
      form_cadastro: {
        cliente_id: null,
        observacao_cliente: "",
        documentos: []
      },
      headers: [
        {text: "Cod. Reanalise", value: "id", align: "start", width: "10%"},
        {text: "Arquivo", value: "arquivo", align: "start", width: "50%"},
        {text: "Data Cadastro", value: "criado_em", align: "center"},
        {text: "Ver Arquivo", value: "verarquivo", align: "center"},
      ],
      headers_file: [
        {text: "Arquivo", value: "name", align: "start",},
        {text: "Remover", value: "remover", align: "center", width: "10%"},
      ],
      reanalise: {
        id: null,
        cliente_id: null,
        status_id: null,
        observacao_cliente: null,
        observacao_auditor: null,
        criado_em: null,
        atualizado_em: null,
        reanalise_documentos: []
      },
    };
  },
  watch: {
    reanalise_id(val) {
      if (val == 0) {
        this.cadastrando = true
        this.form_cadastro.cliente_id = this.cliente_id;
      }
      if (val != null && val > 0) {
        this.form_auditor.reanalise_id = val;
        this.form_auditor.cliente_id = this.cliente_id;
        this.buscarReanalise(val)
      }
    },
  },
  methods: {
    fechar() {
      this.$emit('fechar')
    },
    // verArquivo(item) {
    //   this.dialogIframe = true;
    //   this.itemIframe = item;
    // },
    getTypeImg(url) {
      if (url == null) return;
      var split = url.split(".");
      return split.at(-1);
    },
    async buscarReanalise(id) {
      await reanalise.buscar(id).then(resp => {
        this.reanalise = resp.data;
      });
    },
    async cadastrarReanalise() {
      await reanalise.cadastrar(this.form_cadastro).then(resp => {
        this.setNotificacao({
          status: true,
          mensagem: `Documentos enviado para Reanalise com Sucesso !`,
          categoria: 200
        });
        this.fechar();
      });
    },
    async auditarReanalise() {
      if (this.form_auditor.status_aprovacao == 3 && this.form_auditor.observacao.length < 10) {
        this.setNotificacao({
          status: true,
          mensagem: "Campo Observação deve ter no minimo 10 caracteres",
          categoria: 503
        });
        return;
      }
      await reanalise.editar(this.form_auditor.reanalise_id, this.form_auditor).then(resp => {
        this.setNotificacao({
          status: true,
          mensagem: `Auditoria efetuada com Sucesso !`,
          categoria: 200
        });
        this.fechar();
      });
    }
  }
};
</script>