<template>
  <v-autocomplete
      label="Status da Reanalise"
      placeholder="Selecione o status"
      :items="options_status"
      item-text="text"
      item-value="id"
      outlined
      dense
      v-model="status_selected"
      hide-details
      clearable
      prepend-inner-icon="mdi-list-status"
      v-on:change="(event) => this.$emit('statusSelecionado', status_selected)"
  />
</template>
<!--item-color-->
<script>
export default {
  name: "AutocompleteStatusReanalise",
  props: {
    id: Number | String,
  },
  data: () => ({
        status_selected: null,
        options_status: [
          {id: 1, text: "Pendente"},
          {id: 2, text: "Aprovado"},
          {id: 3, text: "Negado"},
        ],
      }
  ),
  async created() {
    if (this.id) {
      this.status_selected = this.id
    }
  },
  watch: {
    id: function (val) {
      this.status_selected = val;
    }
  },
}
</script>